import { createRouter, createWebHistory } from 'vue-router';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/RegistrationScreen.vue'),
      props: (route) => ({ defaultEntryCode: route.query['entry-code'] }),
    },
    {
      path: '/challenge-details/:token',
      name: 'challenge details',
      component: () => import('./views/ChallengeInformation.vue'),
      props: true,
    },
    {
      path: '/complete-information/:token',
      name: 'complete information',
      component: () => import('./views/CompleteInformation.vue'),
      props: true,
    },
    {
      path: '/admin/sign-in',
      name: 'sign-in',
      component: () => import('./views/SignIn.vue'),
    },
    {
      path: '/admin',
      component: () => import('./views/AdminRoot.vue'),
      children: [
        {
          path: '',
          name: 'admin',
          redirect: '/admin/applicants',
        },
        {
          path: 'applicants',
          name: 'applicants',
          component: () => import('./views/ApplicantList.vue'),
        },
        {
          path: 'templates',
          name: 'templates',
          component: () => import('./views/TemplateList.vue'),
        },
        {
          path: 'entry-codes',
          name: 'entry-codes',
          component: () => import('./views/AdminEntryCodesList.vue'),
        },
        {
          path: 'entry-codes/create',
          name: 'create-entry-code',
          component: () => import('./views/AdminEntryCodesCreate.vue'),
        },
        {
          path: 'entry-codes/edit/:tag',
          name: 'edit-entry-code',
          component: () => import('./views/AdminEntryCodesEdit.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('./views/NotFound.vue'),
    },
  ],
});
